<div class="message-grid-box">
  <app-filter
    id="messageFilter"
    class="message-grid-top"
    [messageCount]="messageList.length"
    (filterExpansionChange)="changeMaxMessageHeight($event)"
    (filterChange)="onFilter($event)"
    (sortChange)="onSort($event)"
  ></app-filter>
  <div class="message-grid-bottom" [ngStyle]="{ 'max-height.px': maxHeight }">
    <div class="message-container" *ngIf="!isLoading()">
      <div class="message-box"
           *ngFor="let message of messageList"
           (click)="onClickMessage(message.id)"
           [ngClass]="{ active: activeMessageId == message.id }">
        <span class="message-title">{{ message.heading | truncate:80 }}</span>
        <span *ngIf="message.location">({{ message.location }})</span>
        <span class="message-indicators">
          <span class="message-target">
            {{
              getMessageTarget(message.messageType)
            }}
          </span>
          <div class="message-status"
               [ngClass]="statusToClass(message.messageStatus)"></div>
        {{ getMessageStatus(message.messageStatus) }}
        </span>
        <div>{{ message.affected }}</div>
        <table>
          <th>Visas från</th>
          <th>Visas till</th>
          <th>Senast ändrad</th>
          <th>Av</th>
          <tr>
            <td>{{ message.from | date: "dd MMM -yy HH:mm" }}</td>
            <td>
              <span *ngIf="message.to && validDate(message.to)">
                {{
                  message.to | date: "dd MMM -yy HH:mm"
                }}
              </span><span *ngIf="!message.to || !validDate(message.to)">Tills vidare</span>
            </td>
            <td>{{ message.lastUpdatedAt | date: "dd MMM -yy HH:mm" }}</td>
            <td>
              {{message.createdOrUpdatedBy | userInfoFilter}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="message-container" *ngIf="isLoading()">
      <app-progress-spinner
        style="left: 45%; position: relative;"
        [isLoading]="isLoading()"
        [showText]="true"
      ></app-progress-spinner>
    </div>
    <div class="message-container" *ngIf="errorText">
      <app-error-box [errorText]="errorText"></app-error-box>
    </div>
  </div>
</div>
